 .header {
  text-align: center;
  margin: 20px 0;
}

 .title {
  composes: appSubtitle from global;
  width: 100%;
}

 .products {
  display: flex;
}

@media (max-width: 760px) {
   .products {
    display: block;
  }
}
