.container {
  composes: content from global;
  margin: 10px 10px;
  flex: 1;
  padding: 0 !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
}

.imageContainer {
  position: relative;
}

.image {
  width: 100%;
}

.name {
  margin-bottom: 5px;
  font-weight: bold;
}

.divider {
  flex: 1;
}

.description {
  color: #999;
  font-size: 14px;
  margin-bottom: 10px;
  max-height: 40px;
  line-height: 20px;
  overflow: hidden;
}

.price {
  font-weight: bold;
  font-size: 1.15em;
}

.offerPrice {
  font-weight: bold;
  text-decoration: line-through;
  opacity: 0.6;
}

.link {
  font-weight: bold;
  border-top: 1px solid #eee;
  padding: 10px 20px;
}
